/* Reset margin and padding for html and body */
html, body {
  margin: 0;         
  padding: 0;        
  height: 100%;      
}

/* Main app container to allow for proper layout */
.App {
  min-height: 100vh; 
  display: flex;
  flex-direction: column;  
  position: relative;
  overflow: hidden;
}

.logo-link {
  text-decoration: none; 
  color: inherit;
  display: flex; 
  align-items: center; 
}

.company-name {
  margin-left: 8px; 
}

.spacer {
  height: 80px; 
}

/* Header styles */
.header {
  width: 100%;                         
  display: flex;                         
  justify-content: space-between;         
  align-items: center;                  
  padding: 15px 50px;                   
  background-color: #001E2D;           
  box-sizing: border-box;               
  position: fixed;                     
  top: 0;                             
  left: 0;                               
  z-index: 2;                             
  color: white;                          
  font-family: Arial, sans-serif;         
  height: 60px;                           
}

/* Content Wrapper */
.content-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}


/* Push main-content down by the height of the header */
.main-content {
  margin-top: 60px; 
  flex-grow: 1;
  padding: 20px; 
}


/* Logo and company name container */
.logo-container {
  display: flex;                          
  align-items: center;                   
  gap: 10px;                          
}

/* Logo image styling */
.logo {
  height: 30px;                           
  width: auto;                           
}

/* Company name styling */
.company-name {
  font-size: 24px;                      
  font-weight: bold;                    
  color: white;                         
}

/* Navigation links container */
.nav-links {
  display: flex;                          
  gap: 20px;                              
  margin-left: auto;                      
}

/* Styling for navigation links */
.nav-links a {
  text-decoration: none;                 
  color: white;                          
  font-size: 14px;                     
  transition: color 0.3s ease;    
}

/* Hover effect for navigation links */
.nav-links a:hover {
  color: #ccc;                          
}

/* Dropdown menu styles */
.nav-item {
  position: relative;             
}

/* Dropdown icon styles */
.nav-item .dropdown-icon {
  margin-left: 3px;                 
  width: 0;
  height: 0;
  border-left: 4px solid transparent;  
  border-right: 4px solid transparent;
  border-top: 6px solid white;
  display: inline-block;
  transition: transform 0.3s ease;    
}

/* When the dropdown icon is rotated */
.nav-item .dropdown-icon.rotated {
  transform: rotate(180deg);             
}

/* Sub-links styling */
.sub-links {
  display: none;                      
  position: absolute;                    
  top: 100%;                              
  left: 0;                               
  background-color: rgba(0, 30, 45, 0.9); 
  padding: 10px;                        
  border-radius: 5px;   
  width: 150px;                   
  z-index: 3;                      
}

/* Styling for sub-links in the dropdown */
.sub-links a {
  display: block;                  
  text-decoration: none;              
  color: white;                        
  font-size: 13px;                      
  padding: 5px 0;                     
  transition: color 0.3s ease;          
}

/* Hover effect for sub-links */
.sub-links a:hover {
  color: #ccc;                          
}

/* Ensure sub-links are visible when dropdown is open */
.nav-item .sub-links {
  display: block;
}

/* Footer styles */
.footer {
  text-align: center;                    
  padding: 20px;                         
  background-color: #001E2D;          
  color: white;                
  width: 100%;                     
  position: relative;         
  margin-top: auto;             
}

/* Footer text styling */
.footer p {
  margin: 0;                        
  font-size: 14px;                    
}


/* Homepage css*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
}

.hero {
  display: flex;
  height: 100vh;
  position: relative;
  overflow: hidden;
  background-image: url('./image/slide1.png');
  /* background-color: black; */
  background-size: cover;
  background-position: center center;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.5);
}

.hero1 {
  display: flex;
  height: 100vh;
  position: relative;
  overflow: hidden;
  background-image: url('./image/slide2.png');
  /* background-color: black; */
  background-size: cover;
  background-position: center center;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.5);
}

.hero2 {
  display: flex;
  height: 100vh;
  position: relative;
  overflow: hidden;
  background-image: url('./image/Slide11.png');
  /* background-color: purple; */
  background-size: cover;
  background-position: center center;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.5);
}
.hero3 {
  display: flex;
  height: 100vh;
  position: relative;
  overflow: hidden;
  background-image: url('./image/slide9.png');
  /* padding: 50px;
  padding-bottom: 80px;  */
  /* background-color: green; */
  background-size: cover;
  background-position: center center;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.5);
}

.hero4 {
  display: flex;
  height: 100vh;
  position: relative;
  overflow: hidden;
  background-image: url('./image/Slide10.png');
  /* padding: 50px;
  padding-bottom: 80px;  */
  /* background-color: green; */
  background-size: cover;
  background-position: center center;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.5);
}

.hero5 {
  display: flex;
  height: 100vh;
  position: relative;
  overflow: hidden;
  background-image: url('./image/Slide7.png');
  /* padding: 50px;
  padding-bottom: 80px;  */
  /* background-color: green; */
  background-size: cover;
  background-position: center center;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.5);
}

.hero6 {
  display: flex;
  height: 100vh;
  position: relative;
  overflow: hidden;
  background-image: url('./image/slide9.png');
  /* padding: 50px;
  padding-bottom: 80px;  */
  /* background-color: green; */
  background-size: cover;
  background-position: center center;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.5);
}

.hero7 {
  display: flex;
  height: 100vh;
  position: relative;
  overflow: hidden;
  background-image: url('./image/Slide8.png');
  /* padding: 50px;
  padding-bottom: 80px;  */
  /* background-color: green; */
  background-size: cover;
  background-position: center center;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.5);
}

.hero8 {
  display: flex;
  height: 100vh;
  position: relative;
  overflow: hidden;
  background-image: url('./image/Slide10.png');
  /* padding: 50px;
  padding-bottom: 80px;  */
  /* background-color: green; */
  background-size: cover;
  background-position: center center;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.5);
}

.content {
  /* border: 1px purple solid; */
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  width: 100%;
  color: white;
  margin-inline: auto;
  text-align: center;
  justify-content: center;
  align-items: center;

}


.text-background {
  background-color: rgba(0, 30, 45, 0.7); 
  padding: 10px 20px;
 
}

.text-background h1 {
  font-size: 56px;
  font-weight: bolder;
  margin: 0;
}

.text-background p {
  font-size: 22px;
  margin: 15px 0 0 0;
  max-width: 550px;
  letter-spacing: 2px;
}

.content h1 {
  font-size: 56px;
  font-weight: bolder;
  margin: 0 0 56px 0;
}

.content p {
  font-size: 22px;
  margin: 15px 0;
  max-width: 550px;
  letter-spacing: 2px;
}

.content a {
  color: inherit;
  text-decoration: none;
  background-color:  #70A3CC;
  padding: 12px 24px;
  font-size: 22px;
  border-radius: 10px;
}

.content-wrapper {
  overflow: hidden;
}

.slide-container {
  height: 100vh;
  position: relative;
  overflow: hidden; 
}


.background-placeholder {
  height: 100vh; 
  background-size: cover;
  background-position: center;
  position: relative;
}

.static-content {
  padding: 50px; 
  background-color: #f5f5f5; 
  min-height: 60vh; 
}



.motion-container {
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

/* Add these styles to your App.css */

.main-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.slides-container {
  height: 100vh;
  width: 100%;
  position: relative;
}

.about-section {
  background-color: #f5f5f5;
  padding: 80px 20px;
  text-align: center;
}

.about-content {
  max-width: 1200px;
  margin: 0 auto;
}

.about-content h2 {
  font-size: 36px;
  color: #001E2D;
  margin-bottom: 20px;
}

.about-content p {
  font-size: 18px;
  color: #333;
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto;
}

.static-content {
  position: relative;
  z-index: 1;
  background-color: white;
}

/* Ensure the slides don't scroll with the page */
.App {
  position: relative;
  overflow: visible;
}

/* Adjust content wrapper to handle scrolling properly */
.content-wrapper {
  overflow-x: hidden;
  position: relative;
}


/* ContactUs Page */      

.contact-page {
  height: 100vh;
  min-height: calc(100vh - 60px); 
  padding: 0;
  margin: 0;
}

.contact-container {
  display: flex;
  margin: 0 auto;
  display: flex;
  background-color: white;
  border-radius: 0;
  overflow: hidden;
  width: 100%;
  
}

.contact-image {
  flex: 1;
  position: relative;
  background-color: #001E2D;
  height: 100vh;
}

.contact-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.8;
}

.image-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  width: 100%;
  padding: 20px;
}

.image-overlay h2 {
  font-size: 36px;
  margin-bottom: 10px;
}

.image-overlay p {
  font-size: 18px;
  opacity: 0.9;
}

.contact-form-container {
  flex: 1;
  padding: 60px 80px;
  background-color: white;
}

.contact-form-container h1 {
  color: #001E2D;
  margin-bottom: 10px;
  font-size: 32px;
}

.contact-form-container {
  flex: 1;
  padding: 40px;
  background-color: white;
}

.contact-form-container h1 {
  color: #001E2D;
  margin-bottom: 10px;
  font-size: 32px;
}

.form-subtitle {
  color: #666;
  margin-bottom: 30px;
  font-size: 16px;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.form-group label {
  font-size: 14px;
  color: #333;
  font-weight: 500;
}

.form-group input,
.form-group textarea {
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 14px;
  transition: border-color 0.3s ease;
}

.form-group input:focus,
.form-group textarea:focus {
  outline: none;
  border-color: #70A3CC;
}

.submit-button {
  background-color: white;
  color: #001E2D;
  padding: 14px 28px;
  border: 2px solid #001E2D;
  border-radius: 6px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 10px;
}

.submit-button:hover {
  background-color: ##001E2D;
  color: white;
}

/* Responsive design */
@media (max-width: 768px) {
  .contact-container {
    flex-direction: column;
  }

  .contact-image {
    height: 300px;
  }

  .contact-form-container {
    padding: 20px;
  }
}


/* SideNav.css */
.side-nav {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 200px;
  background-color: #001E2D;
  color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.side-nav h2 {
  margin-bottom: 30px;
  font-size: 24px;
}

.side-nav ul {
  list-style: none;
  padding: 0;
}

.side-nav li {
  font-size: 18px;
  margin-bottom: 20px;
  cursor: pointer;
}

.side-nav li:hover {
  color: #70A3CC;
}

button {
  background-color: #001E2D;
  color: white;
  border: 2px solid white;
  border-radius: 8px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
}

button:hover {
  background-color: #003246; 
  border-color: #003246; 
}



.profiles-section {
  padding: 40px 20px;
  background-color: #ffffff;
  border-top: 1px solid #e0e0e0;
  padding-bottom: 60px;
  margin-bottom: 80px;
}

.profiles-frame {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.profile {
  cursor: pointer;
  flex: 1 1 30%; 
  max-width: 50%;
  box-sizing: border-box;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 15px;
  transition: box-shadow 0.3s ease;
}

.profile:hover {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.profile img {
  width: 100%;
  border-radius: 5px;
  margin-bottom: 10px;
}

.expanded-profile {
  margin-top: 10px;
  padding: 10px;
  border-top: 1px solid #ddd;
  background-color: #f9f9f9;
}

/* Responsive Design */
@media (max-width: 768px) {
  .profile {
    flex: 1 1 100%; 
    max-width: 100%;
  }
}

.profiles-frame img {
  width: 150px; 
  height: 150px; 
  object-fit: cover; 
  border-radius: 50%; 
}
